import { template as template_193b4bc468714ad3a3e4e89ab21788bc } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_193b4bc468714ad3a3e4e89ab21788bc(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
